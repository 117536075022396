import { React, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";

const GamesOverview = (props) => {
    const { loggedIn, setLoggedIn } = props;
    const [games, setGames] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const getGames = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"))
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/games`,{
                        headers: {
                            'jwt-token': user.token
                          }
                    }
                );
                let games = await response.json()
                games.reverse();
                setGames(games);
            } catch (e) {
                console.log(e);
            }
        };
        getGames();
    }, []);

    const handleAddGame = async (event) => {
        event.preventDefault();
        try {
            // API logic here
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/new`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'jwt-token': user.token
                    }
                }
            );
            const newGame = await response.json();
            console.log(newGame)
            setGames([newGame, ...games]);
            navigate(`/games/${newGame.id}/settings`)
        } catch (e) {
            console.log(e);
        }
    };

    const logOut = () => {
        localStorage.removeItem("user")
        props.setLoggedIn(false)
        navigate(`/`)
    }

    return <div className="mainContainer">
        <button className="newgame" onClick={(event)=>(handleAddGame(event))}>New game <span className="material-symbols-outlined">add</span></button>
        <button className="logout secondary" onClick={logOut}>Log out</button>
        <div className="gamesContainer">
            {games.map((game,i) => (
                <a key={i} className="gameRow" href={'games/'+game.id}>
                    <h2 gameid={game.id}>{game.title}</h2>
                    <span className="material-symbols-outlined">chevron_right</span>
                </a>
            ))}
        </div>
    </div>
}


export default GamesOverview