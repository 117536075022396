import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SingleGamePlaying = (props) => {
    const { loggedIn } = props,
        { gameid } = useParams();
    const [singleGame, setSingleGame] = useState([]),
        [players, setPlayers] = useState([]),
        [battlers, setBattlers] = useState([]),
        [currentBattle, setCurrentBattle] = useState([]),
        [playedBattles, setPlayedBattles] = useState([]),
        [heatNth, setHeatNth] = useState(1),
        [mexicans, setMexicans] = useState(0),
        [lowScore, setLowScore] = useState(0),
        [popupBattleState, setPopupBattleState] = useState("hidden"),
        [popupLoserState,setPopupLoserState] = useState("hidden"),
        [popupLoser, setPopupLoser] = useState("");

    useEffect(() => {
        getGame(false);
    }, []);

    const getGame = async (showWinner) => {
        const user = JSON.parse(localStorage.getItem("user"))
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}`, {
                headers: {
                    'jwt-token': user.token
                }
            }
            );
            let game = await response.json();
            let playerObj = [];
            for (const playerName of game.players) {
                playerObj.push({ name: playerName, picked: 'false' })
            }
            console.log(game)
            setPlayers(playerObj);
            setBattlers(playerObj);
            setHeatNth(game.rounds.reverse()[0].heats.length + 1);
            setSingleGame(game.players);

            //Check if there is a winner;
            if( showWinner ){
                if( game.rounds.length > 1 && game.rounds[0].heats.length === 0 ){
                    determineWinner(game);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    const setSelectedPlayer = async (e, player) => {
        const a = e === undefined ? '' : e.preventDefault();
        var newPlayerObj = []
        for (const playerRow of players) {
            if (playerRow.name === player) {
                newPlayerObj.push({ name: playerRow.name, picked: 'true' });
            } else {
                newPlayerObj.push({ name: playerRow.name, picked: 'false' });
            }
        }
        console.log(player, newPlayerObj)
        setPlayers(newPlayerObj);
    }
    const setSelectedBattlers = (e, player, reset) => {
        const a = e === undefined||e === '' ? '' : e.preventDefault();
        var newPlayerObj = [],
            newCurrentBattle = currentBattle;
        for (const playerRow of battlers) {
            if( reset === true ){
                newPlayerObj.push({ name: playerRow.name, picked: 'false' });
            } else {
                if (playerRow.name === player) {
                    if (playerRow.picked == 'true') {
                        newPlayerObj.push({ name: playerRow.name, picked: 'false' });
                        delete (newCurrentBattle[playerRow.name]);
                    } else {
                        newPlayerObj.push({ name: playerRow.name, picked: 'true' });
                        newCurrentBattle[playerRow.name] = {
                            name: playerRow.name,
                            score: 0
                        };
                    }
                } else {
                    newPlayerObj.push({ name: playerRow.name, picked: playerRow.picked });
                }
            }
        }
        setCurrentBattle(newCurrentBattle);
        console.log(newPlayerObj)
        setBattlers(newPlayerObj);
    }
    const updateCurrentBattleScore = (e, playerkey) => {
        var newCurrentBattle = currentBattle;
        if (checkValueValidity(parseInt(e.target.value), false)) {
            e.target.classList.remove('error');
        } else {
            e.target.classList.add('error');
        }
        newCurrentBattle[currentBattle[playerkey].name].score = e.target.value;
        setCurrentBattle(newCurrentBattle);

    }
    const updateMexicanScore = (add) => {
        if (add === true) {
            setMexicans(mexicans + 1);
        } else {
            setMexicans(Math.max(0, mexicans - 1));
        }
    }
    const setHeat = async (e,theat) => {
        //Check if a loser is picked. The rest isn't needed;
        var loser = '';
        for (const player of players) {
            if (player.picked !== 'false') {
                loser = player.name;
            }
        }
        if (loser === '' && theat === undefined) {

        } else {
            var theat = theat === undefined ? {
                loser: loser,
                mexicans: mexicans,
                losingvalue: lowScore,
                battles: playedBattles
            } : theat;
            try {
                // API logic here
                const user = JSON.parse(localStorage.getItem("user"))
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/game/${gameid}/heat`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'jwt-token': user.token
                        }, body: JSON.stringify({ heat: theat })
                    }
                );
                const addedHeat = await response.json();
                console.log(addedHeat);
                resetPage();
            } catch (err) {
                console.log(err);
            }
        }
    }
    const submitBattle = async (e) => {
        //Check the score
        console.log(e)
        console.log(currentBattle)
        var notAllowed = false;
        if( Object.keys(currentBattle).length < 2 ){
            return false;
        }
        for (const key in currentBattle) {
            if (!checkValueValidity(parseInt(currentBattle[key].score), false)) {
                notAllowed = true;
            }
        }
        if (notAllowed === true) {
            return false;
        } else {
            //Add the battle
            //Check if this game is finished. 
            // - If it is; add the score to the current score en finish the heat. Reset page
            // - If it isn't;  add the score to the current score and clear the battle
            //   - add a line for each finished battle?
            var addedMex = 0, losers = [], losingScore = 1000, battlePlayers = [];
            for (const key in currentBattle) {
                battlePlayers.push(currentBattle[key].name)
                var tscore = parseInt(currentBattle[key].score);
                if (parseInt(tscore) === 21) {
                    addedMex += 1;
                    tscore = 700;
                }
                if (tscore < losingScore) {
                    losingScore = tscore;
                    losers = [currentBattle[key].name];
                } else if (tscore == losingScore) {
                    losers.push(currentBattle[key].name);
                }
            }
            losingScore = losingScore === 700 ? 21 : losingScore;
            var tempPlayedBattles = playedBattles === undefined ? [] : playedBattles;
            tempPlayedBattles.push({ losingScore: losingScore, losers: losers, players: battlePlayers })
            for (let input of e.target.parentElement.querySelectorAll('input')) {
                input.value = ''
            }

            setPlayedBattles(tempPlayedBattles);
            setMexicans(mexicans + addedMex);
            setLowScore(losingScore);
            console.log(tempPlayedBattles)

            if (losers.length === 1) {
                var newPlayerObj = [];
                for (const playerRow of players) {
                    if (playerRow.name === losers[0]) {
                        newPlayerObj.push({ name: playerRow.name, picked: 'true' });
                    } else {
                        newPlayerObj.push({ name: playerRow.name, picked: 'false' });
                    }
                }
                setPlayers(newPlayerObj);
                setHeat('',{
                    loser: losers[0],
                    mexicans: mexicans+addedMex,
                    losingvalue: losingScore,
                    battles: tempPlayedBattles
                });
            }
        }
    }
    const determineWinner = async (newGame)=>{
        // setHeatNth
        //Heats al allready reversed, so just take the minus 1 upper one;
        const prevRound = newGame.rounds[1];
        var scoreObj = {}, loser = "", highScore = 0;
        for (const heat of prevRound.heats) {
            if( scoreObj[heat.loser] === undefined ){
                scoreObj[heat.loser] = 0;
            }
            scoreObj[heat.loser] += 2 ** heat.mexicans;
            if( scoreObj[heat.loser] > highScore ){
                highScore = scoreObj[heat.loser]; loser = heat.loser;
            }
        }
        setPopupLoserState("shown");
        setPopupLoser(loser)
    }

    const resetPage = () => {
        setMexicans(0);
        setLowScore(0);
        setPlayedBattles([]);
        setPopupBattleState("hidden");
        document.querySelectorAll('.lowScore input')[0].value = 0
        setSelectedBattlers('','',true);
        getGame(true);
    }

    return <>
        <h2>Heat {heatNth}</h2>
        <div className="pickLoser">
            <h3>Who is the loser?</h3>
            <div className="loserHolder">
                {players.map((player) => (
                    <span onClick={(e) => { setSelectedPlayer(e, player.name); console.log(players) }} data-selected={player.picked} key={player.name}>{player.name}</span>
                ))}
            </div>
            <div className="lowScore">
                <label>Losing score</label>
                <input type="number" onChange={(e) => { setLowScore(e.target.value) }} />
            </div>
            <div className="mexicanScore">
                <label>Mexicans</label>
                <div className="mexicanScoreHolder">
                    <div className="mexicanScoreMin" onClick={() => { updateMexicanScore(false) }}>-</div>
                    <div className="mexicanScore">{mexicans}</div>
                    <div className="mexicanScoreAdd" onClick={() => { updateMexicanScore(true) }}>+</div>
                </div>
                <div className="mexicanHats">
                    {/* <img src="https://www.carnavalsland.nl/sombrero-mexico-luxe-blauw.html"/> */}
                </div>
            </div>
            <div className="battleButton">
                <button onClick={(e) => { e.preventDefault(); setPopupBattleState("shown"); setCurrentBattle({}) }}>
                <span className="material-symbols-outlined">swords</span>
                    <span className="text">Battle</span>
                <span className="material-symbols-outlined">swords</span>
                </button>
            </div>
            <div className="heatButtons">
                <button onClick={(e) => { setHeat(e) }}>Done</button>
            </div>
            
            <div className={popupLoserState + " hiddenPopup popupLoser"} onClick={(e) => {
                if (e.currentTarget != e.target) return;
                setPopupLoserState("hidden")}}>
                <div className="content">
                    <h3><span>{popupLoser}</span> lost the round!</h3>
                </div>
            </div>

            <div className={popupBattleState + " hiddenPopup"} onClick={(e) => {
                if (e.currentTarget != e.target) return;
                setPopupBattleState("hidden")
            }}>
                <div className="content">
                    <div className="pickBattlers">
                        <h3>Pick the players</h3>
                        <div className="battlersHolder">
                            {battlers.map((battler) => (
                                <span onClick={(e) => { setSelectedBattlers(e, battler.name) }} data-selected={battler.picked} key={battler.name}>{battler.name}</span>
                            ))}
                        </div>
                    </div>
                    <div>
                        {playedBattles.map((battle,i)=>(
                            <div className="battleDraw" key={battle.losers.join(',')+i}>Draw:    {battle.losers.join(' / ')}<span>{battle.losingScore}</span></div>
                        ))}
                    </div>
                    <div className="versus">
                        <div className="vsIcon"><span>VS</span></div>
                        {Object.entries(currentBattle).map(([playerkey, player], i) => (
                            <div className="versusPlayer" key={player.name}>
                                <span>{player.name}</span>
                                <input type="number" data-player={player.name} onChange={(e) => { updateCurrentBattleScore(e, playerkey) }} />
                            </div>
                        ))}
                    </div>
                    <button onClick={(e) => { submitBattle(e) }}>Submit</button>
                </div>
            </div>
        </div>
    </>
}
export default SingleGamePlaying

const checkValueValidity = (number, allowZero) => {
    allowZero = allowZero === undefined ? true : allowZero;
    const correctNumbers = [21, 31, 32, 41, 42, 43, 51, 52, 53, 54, 61, 62, 63, 64, 65, 100, 200, 300, 400, 500, 600];
    if (allowZero) { correctNumbers.push(0) }
    if (correctNumbers.includes(number)) {
        return true;
    }
    return false;
}

/*
v Show the current heat; 
v Pick a loser
v Add losing score
v Add mexicans
Add a battle 
    - ignore previous loser and score
    - add players
    - add losing scores
    - atleast 2 players, up to unlimited


Nice to have;
- Give the option to revisit previous heats
*/
