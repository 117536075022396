import { React } from "react"
import { useParams, useNavigate } from "react-router-dom";
import GamesOverview from "./components/Games/GamesOverview.js";
import SingleGameNavigation from "./components/Games/SingleGameNavigation.js";
import SingleGame from "./components/Games/SingleGamePlaying.js";
import SingleGameDashboard from "./components/Games/SingleGameDashboard.js";
import SingleGameSettings from "./components/Games/SingleGameSettings.js";

const Games = (props) => {
    const { loggedIn, setLoggedIn, setEmail } = props,
        { gameid, page } = useParams();
    const navigate = useNavigate();

    if( loggedIn === undefined || loggedIn === false ){
        navigate(`/`);
    } else if( gameid !== undefined ){
        if( page === "dashboard" ){
            return <div className="mainContainer SingleGameDashboard">
                <SingleGameDashboard/>
                <SingleGameNavigation gameid={gameid} page="dashboard"/>
            </div>;
        } else if (page === "settings"){
            return <div className="mainContainer SingleGameSettings">
                <SingleGameSettings/>
                <SingleGameNavigation gameid={gameid} page="settings"/>
            </div>;
        } else {
            return <div className="mainContainer SingleGame">
                <SingleGame/>
                <SingleGameNavigation gameid={gameid} page="playing"/>
            </div>;
        }
    } else {
        return <GamesOverview loggedIn={loggedIn} setLoggedIn={setLoggedIn} setEmail={setEmail} />;
    }
}

export default Games

